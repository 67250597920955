import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { EditorCell } from "../cells/editorCell";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import {
	ImgLavorazione,
	Button,
	ProgressBar,
	Checkbox,
	ProcessingNotesModal,
	NewActivityModal,
} from "../../index";
import { controllaEsistenzaDocumento, updateCommessa, updateStatusLavorazione } from "../../../services/";

import { Warning, Label, AssignmentTurnedIn } from "@material-ui/icons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { Link, useNavigate } from "react-router-dom";

import {
	COMMESSA_TYPES,
	PUNTEGGI_LAVORAZIONI_STATUS,
	COMMESSA_STATUS,
	COMMESSA_STATUS_COLOR,
} from "../../../utils/constants";
import {
	mountMaterialNames,

} from "../../../utils/";
import { mountMaterialNamesWithAzienda } from "../../../utils/functionsUtils";
import { COMMESSEDETAIL } from "../../../navigation/constants";

import { TaglioGrey, WjGrey } from "../../../assets/images/svg";
import { canEdit, getCommentiLavorazioniFormatted, getEasterSunday, getEasterMonday } from "../../../utils/functionsUtils";
import { USER_ROLES } from "../../../utils/constants";
import { DOWNLOAD_DOCUMENTI } from "../../../services/constants";
import {setToast} from "../../../redux";

import { StatusCommessaRedYellow } from "../../../assets/images/svg";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;
		min-width: 1192px;

		display: flex;
		flex-direction: column;
		align-items: flex-end;

		background-color: ${(props) =>
				props.isGold ?
				"var(--gold-yellow)"
				: "var(--white)"};
		border-bottom: 1px solid var(--grey-1);

		.info-cliente-container {
			cursor: ${(props) => props.isAdmin ? "pointer" : "initial"};
			& * {
				cursor: ${(props) => props.isAdmin ? "pointer" : "initial"};
				color: ${(props) => props.colorCommessa} !important;
			}
		}

		.cell-padding {
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	&:hover {
		background-color: ${(props) =>
			props.isGold ?
				"var(--gold-yellow-hover)"
				:
				"var(--grey-background)"};
	}
`;

const Div = styled.div`
	& {
		max-width: 100%;

		display: flex;
		align-items: center;
		flex-direction: row;
	}
`;

const Img = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;

	margin-right: 16px;
`;


/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowCommesse = (props) => {
	const [data, setData] = useState(props.data);
	const user = useSelector((state) => state.user || {});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showAttivitaModal, setShowAttivitaModel] = useState(false);
	const [showProcessingModal, setShowProcessingModal] = useState();
	var today = new Date(); //'2024-04-26T06:30:00'
	const isRitardo = useMemo(() => moment(today) >= moment(data.dt_consegna), [data]);
	const isWorking = useMemo(() => moment(today) >= moment(data.dt_lavorazione) && moment(today) < moment(data.dt_consegna), [data]);
	const isAnticipo = useMemo(() => moment(today) < moment(data.dt_lavorazione), [data]);
	const isRedYellowIcon = useMemo(() => (data.hour_consegna === "06:00" || data.hour_consegna === "06:30") &&  moment(data.dt_consegna) <= checkCorrectDay() && moment(data.dt_lavorazione).isSame(moment(today), 'day'), [data]);
	const isYellowIcon = useMemo(() => (data.hour_consegna === "06:00" || data.hour_consegna === "06:30") &&  moment(data.dt_consegna) > checkCorrectDay() && moment(data.dt_lavorazione).isSame(moment(today), 'day'), [data]);

	const [documentsState, setDocumentsState] = useState({
		dwg_esecutivo: {
			exist: false,
			filename: ""
		}
	});

	useEffect(() => {

		/*const anno_commessa = moment(data.dt_creazione_commessa).format("YYYY"); //2022
		const numero_commessa = data.numero_commessa; // "76D"

		controllaEsistenzaDocumento("dwg", numero_commessa, anno_commessa).then((response) => {
			if (response.result) {
				documentsState.dwg_esecutivo.exist = response.result.success;
				documentsState.dwg_esecutivo.filename = `${numero_commessa}-${anno_commessa}.dwg`;
				setDocumentsState({...documentsState});
			}
		})*/
		if	(data.dwg_exist) {
			const anno_commessa = moment(data.dt_creazione_commessa).format("YYYY"); //2022
			const numero_commessa = data.numero_commessa; // "76D"

			documentsState.dwg_esecutivo.exist = true;
			documentsState.dwg_esecutivo.filename = `${numero_commessa}-${anno_commessa}.dwg`;
			setDocumentsState({...documentsState});
		}
	}, []);

	const commenti_lavorazioni_obj = getCommentiLavorazioniFormatted(data);

	const getSumPunteggiLavorazione = (only_green) => {
		let sum = 0;

		Object.keys(data.punteggi_lavorazioni).forEach((key) => {
			if (only_green) {
				if (
					(data.punteggi_lavorazioni[key].status == PUNTEGGI_LAVORAZIONI_STATUS.GREEN)||
					(data.punteggi_lavorazioni[key].status == PUNTEGGI_LAVORAZIONI_STATUS.BLUE)
				) {
					sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
				}
			} else {
				sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
			}
		});

		return sum.toFixed(2);
	};

	const saveCommessa = async (newData) => {
		let result = await updateCommessa(data._id, {...newData, _id: data._id});
		/*
		if (props.onChange) {
			newData.modified = true;
			props.onChange({...data, ...newData});
		}
		 */
		if (props.onChange) {
			return { ...data, ...result.result.commessa, modified: true };
			//props.onChange({...data, ...(result.result)});
		}
	};

	const updateLavorazioneCommessa = async (newData) => {

		let result = await updateStatusLavorazione(data._id, {...newData, _id: data._id});

		if(!!result.error){
			dispatch(
				setToast({
					show: true,
					title: "commesse.toastUpdateStatus.error.title",
					description: "commesse.toastUpdateStatus.error.description",
					variant: "danger",
				})
			);
			return { ...newData, modified: false };

		}else if (props.onChange) {
			return { ...data, ...result.result, modified: true };
			//props.onChange({...data, ...(result.result)});
		}

		/*.then(({ result, error }) => {
			if (error) {
				console.log(error);
			}

			if (props.onChange) {
				newData.modified = true;
				props.onChange({...data, ...result});
			}

		});*/

	}

	const getColorFromCommessa = (show_anticipo) => {
		if (data.cd_type != COMMESSA_TYPES.ATTIVITA) {
			if (moment(today) < moment(data.dt_consegna) && show_anticipo) {
				return "var(--green)";
			} else {
				return COMMESSA_STATUS_COLOR[data.cd_status];
			}
		} else {
			return "";
		}
	};

	function checkCorrectDay(){
		let checkDay = moment(today).day();

		if(checkDay === 5 || isChristmasOrEaster()){ // Se è venerdì
			return moment(data.dt_lavorazione).add(3,'days');
		} else if(checkDay === 6 || isHoliday()){ // Se è sabato
			return moment(data.dt_lavorazione).add(2,'days');
		} else {
			return moment(data.dt_lavorazione).add(1,'days');
		}
	}

	function isHoliday(){

		let giorno = moment(today).date();
		let mese = moment(today).month() + 1;

		if ((giorno === 31 && mese === 12) || (giorno === 5 && mese === 1) || (giorno === 24 && mese === 4) || (giorno === 30 && mese === 4) || (giorno === 1 && mese === 6) || (giorno === 14 && mese === 8) || (giorno === 31 && mese === 10) || (giorno === 7 && mese === 12) || (giorno === 25 && mese === 12)) {
			return true;
		} else {
			return false;
		}
	}

	function isChristmasOrEaster(){
		let giorno = moment(today).date();
		let mese = moment(today).month() + 1;

		let giornoPasqua = moment(getEasterSunday()).date();
		let mesePasqua = moment(getEasterSunday()).month();

		if((giorno === giornoPasqua-1 && mese === mesePasqua-1) || (giorno === 24 && mese === 12)){
			return true;
		} else {
			return false;
		}
	}

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			isGold={data.is_gold}
			isAdmin={user.cd_privilege == USER_ROLES.ADMIN}
			colorCommessa={getColorFromCommessa(false)}
			style={props.style}
		>
			<Div
				style={{
					width: "100%",
				}}
			>
				<div
					className="cell-padding"
					style={{
						width: "3%",
					}}
				>
					{data.cd_type == COMMESSA_TYPES.ATTIVITA ? (
						<AssignmentTurnedIn
							style={{
								color: getColorFromCommessa(true),
							}}
						/>
					) : isRedYellowIcon ? (
						<Img
							src={StatusCommessaRedYellow}
						/>
					) : (
						<Label
							style={{
								color: 	isRitardo ?
											"var(--red)"
										:
										isYellowIcon ?
											"var(--yellow)"
										:

										isWorking ?
											"var(--yellow)"
										:
										"var(--green)",
							}}

						/>
					)}
				</div>

				<TextCell
					primaryText={data.priority}
					style={{
						width: "5%",
					}}
				/>

				<Link
					to={user.cd_privilege == USER_ROLES.ADMIN && data.cd_type != COMMESSA_TYPES.ATTIVITA ? COMMESSEDETAIL+`?commessaId=${data._id}` : ""}
					style={{
						width: "10%",
						textDecoration: "none"
					}}
				>
					<TextCell
						className="info-cliente-container"
						primaryText={data.cliente ? data.cliente.nomecompleto : ""}
						secondaryText={`${data.riferimento}`}
						lineOnText={data.is_sospesa}
						onClick={() => {
							if(user.cd_privilege == USER_ROLES.ADMIN){
								if (data.cd_type == COMMESSA_TYPES.ATTIVITA) {
									setShowAttivitaModel(true);
								}
							}
						}}
					/>
				</Link>

				{data.cd_type == COMMESSA_TYPES.ATTIVITA ? (
					<>
						<TextCell
							primaryText={data.descrizione_attivita}
							style={{
								width: "82%",
							}}
						/>
					</>
				) : (
					<>
						<TextCell
							primaryText={mountMaterialNames(data, props.aziende)}
							style={{
								width: "10%",
							}}
						/>
						{canEdit(user) ?
						(<EditorCell
							id={"commento_commessa"}
							value={data.commento}

							type="text"
							onChange={async (id, value) => {
								const updateValue = {
									commento: value
								};
								//const newData = { ...data, ...updateValue, modified: true };
								//setData(newData);
								//saveCommessa(updateValue);

								let new_commessa = await saveCommessa(updateValue);
								delete new_commessa.commento;
								const newData = { ...data, ...new_commessa, modified: true };
								setData(newData);

							}}
							style={{
								width: "10%",
							}}
						/>) :
						(<TextCell
							primaryText={data.commento && data.commento != "" ? data.commento : "-"}
							style={{
								width: "10%",
							}}
						/>)}


						<div
							className="cell-padding"
							style={{
								width: "10%",
							}}
						>
							<TextCell
								primaryText={
									<span>
										{data.punteggi_lavorazioni.tgm.comment && data.punteggi_lavorazioni.tgm.comment != "" ? ("TGM: " + data.punteggi_lavorazioni.tgm.comment) : ""}
										<br/>
										{data.punteggi_lavorazioni.tga.comment && data.punteggi_lavorazioni.tga.comment != "" ? ("TGA: " + data.punteggi_lavorazioni.tga.comment) : ""}
									</span>
								}
								onClick={() => {
									setShowProcessingModal(true);
								}}
								style={{
									cursor: "pointer"
								}}
							/>
						</div>

						<TextCell
							primaryText={data.numero_commessa}
							style={{
								width: "4%",
							}}
						/>

						<div
							className="cell-padding"
							style={{
								width: "3%",
							}}
						>
							{
								documentsState.dwg_esecutivo.exist ?
									<a
										href={documentsState.dwg_esecutivo.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("dwg", data.numero_commessa, moment(data.dt_creazione_commessa).format("YYYY"))}` : null}
										target="_blank"
										style={{
											textDecoration: "none"
										}}
									>
										<FileDownloadOutlinedIcon
											style={{
												cursor: "pointer",
											}}
										/>
									</a>
								:
									""
							}
						</div>

						<div
							className="cell-padding"
							style={{
								display: "flex",
								width: "25%",
							}}
						>
							<ImgLavorazione
								keyLavorazione="mat"
								lavorazione={data.punteggi_lavorazioni.mat}
								onClick={async (lavorazione) => {
									if(canEdit(user)){
										const updateValue = {
											punteggi_lavorazioni: data.punteggi_lavorazioni
										};
										updateValue.punteggi_lavorazioni.mat = lavorazione;
										//const newData = { ...data, ...updateValue, modified: true };
										//setData(newData);

										let bodyToSend = { lavorazione: "mat",  status: lavorazione };
										let newData = await updateLavorazioneCommessa(bodyToSend);
										if(newData && !!newData.modified){
											setData(newData);
										}
									}
								}}
							/>
							{
								parseInt(data.cd_status) >= parseInt(COMMESSA_STATUS.CONFERMATO) && !data.is_sospesa ?
									<>
										{
											data.punteggi_lavorazioni.tga.status != PUNTEGGI_LAVORAZIONI_STATUS.GREY ? (
											<ImgLavorazione
												keyLavorazione="tga"
												lavorazione={data.punteggi_lavorazioni.tga}
												title={commenti_lavorazioni_obj.tga}
												onClick={async (lavorazione) => {
													if(canEdit(user)){
														const updateValue = {
															punteggi_lavorazioni: data.punteggi_lavorazioni
														};
														updateValue.punteggi_lavorazioni.tga = lavorazione;
														//const newData = { ...data, ...updateValue, modified: true };
														//setData(newData);
														//saveCommessa(updateValue);

														let bodyToSend = { lavorazione: "tga",  status: lavorazione };
														let newData = await updateLavorazioneCommessa(bodyToSend);
														if(newData && !!newData.modified){
															setData(newData);
														}
													}
												}}
											/>
											) : data.punteggi_lavorazioni.tgm.status != PUNTEGGI_LAVORAZIONI_STATUS.GREY ? (
												<ImgLavorazione
													keyLavorazione="tgm"
													lavorazione={data.punteggi_lavorazioni.tgm}
													title={commenti_lavorazioni_obj.tgm}
													onClick={async (lavorazione) => {
														if(canEdit(user)){
															const updateValue = {
																punteggi_lavorazioni: data.punteggi_lavorazioni
															};
															updateValue.punteggi_lavorazioni.tgm = lavorazione;
															//const newData = { ...data, ...updateValue, modified: true };
															//setData(newData);
															//saveCommessa(updateValue);
															let bodyToSend = { lavorazione: "tgm",  status: lavorazione };
															let newData = await updateLavorazioneCommessa(bodyToSend);
															if(newData && !!newData.modified){
																setData(newData);
															}
														}
													}}
												/>
											) : (
												<Img src={TaglioGrey} />
											)
										}
										<ImgLavorazione
											keyLavorazione="coste"
											lavorazione={data.punteggi_lavorazioni.coste}
											title={commenti_lavorazioni_obj.coste}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.coste = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "coste",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
										{data.punteggi_lavorazioni.wja.status !=
										PUNTEGGI_LAVORAZIONI_STATUS.GREY ? (
											<ImgLavorazione
												keyLavorazione="wja"
												lavorazione={data.punteggi_lavorazioni.wja}
												title={commenti_lavorazioni_obj.wja}
												onClick={async (lavorazione) => {
													if(canEdit(user)){
														const updateValue = {
															punteggi_lavorazioni: data.punteggi_lavorazioni
														};
														updateValue.punteggi_lavorazioni.wja = lavorazione;
														//const newData = { ...data, ...updateValue, modified: true };
														//setData(newData);
														//saveCommessa(updateValue);
														let bodyToSend = { lavorazione: "wja",  status: lavorazione };
														let newData = await updateLavorazioneCommessa(bodyToSend);
														if(newData && !!newData.modified){
															setData(newData);
														}
													}
												}}
											/>
										) : data.punteggi_lavorazioni.wjm.status !=
										PUNTEGGI_LAVORAZIONI_STATUS.GREY ? (
											<ImgLavorazione
												keyLavorazione="wjm"
												lavorazione={data.punteggi_lavorazioni.wjm}
												title={commenti_lavorazioni_obj.wjm}
												onClick={async (lavorazione) => {
													if(canEdit(user)){
														const updateValue = {
															punteggi_lavorazioni: data.punteggi_lavorazioni
														};
														updateValue.punteggi_lavorazioni.wjm = lavorazione;
														//const newData = { ...data, ...updateValue, modified: true };
														//setData(newData);
														//saveCommessa(updateValue);
														let bodyToSend = { lavorazione: "wjm",  status: lavorazione };
														let newData = await updateLavorazioneCommessa(bodyToSend);
														if(newData && !!newData.modified){
															setData(newData);
														}
													}
												}}
											/>
										) : (
											<Img src={WjGrey} />
										)}
										<ImgLavorazione
											keyLavorazione="cnc"
											lavorazione={data.punteggi_lavorazioni.cnc}
											title={commenti_lavorazioni_obj.cnc}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.cnc = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "cnc",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
										<ImgLavorazione
											keyLavorazione="alz"
											lavorazione={data.punteggi_lavorazioni.alz}
											title={commenti_lavorazioni_obj.alz}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.alz = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "alz",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
										<ImgLavorazione
											keyLavorazione="inc"
											lavorazione={data.punteggi_lavorazioni.inc}
											title={commenti_lavorazioni_obj.inc}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.inc = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "inc",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
										<ImgLavorazione
											keyLavorazione="fin"
											lavorazione={data.punteggi_lavorazioni.fin}
											title={commenti_lavorazioni_obj.fin}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.fin = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "fin",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
										<ImgLavorazione
											keyLavorazione="imb"
											lavorazione={data.punteggi_lavorazioni.imb}
											title={commenti_lavorazioni_obj.imb}
											onClick={async (lavorazione) => {
												if(canEdit(user)){
													const updateValue = {
														punteggi_lavorazioni: data.punteggi_lavorazioni
													};
													updateValue.punteggi_lavorazioni.imb = lavorazione;
													//const newData = { ...data, ...updateValue, modified: true };
													//setData(newData);
													//saveCommessa(updateValue);
													let bodyToSend = { lavorazione: "imb",  status: lavorazione };
													let newData = await updateLavorazioneCommessa(bodyToSend);
													if(newData && !!newData.modified){
														setData(newData);
													}
												}
											}}
										/>
									</>
								:
									""
							}
						</div>

						<div
							className="cell-padding"
							style={{
								display: "flex",
								width: "10%",
							}}
						>
							<ImgLavorazione
								keyLavorazione="acc"
								lavorazione={data.punteggi_lavorazioni.acc}
								title={commenti_lavorazioni_obj.acc}
								onClick={async (lavorazione) => {
									if(canEdit(user)){
										const updateValue = {
											punteggi_lavorazioni: data.punteggi_lavorazioni
										};
										updateValue.punteggi_lavorazioni.acc = lavorazione;
										//const newData = { ...data, ...updateValue, modified: true };
										//setData(newData);
										//saveCommessa(updateValue);
										let bodyToSend = { lavorazione: "acc",  status: lavorazione };
										let newData = await updateLavorazioneCommessa(bodyToSend);
										if(newData && !!newData.modified){
											setData(newData);
										}
									}
								}}
							/>
							<Checkbox
								id="chk_qa"
								large
								checked={data.qa.is_done}
								onChange={async (e) => {
									if(canEdit(user)){
										const checked = e.target.checked;
										const updateValue = {
											qa: {}
										};
										if (checked) {
											updateValue.qa = {
												is_done: true,
												user: user.nomecompleto,
												dt_qa: moment().toISOString(),
											};
										} else {
											updateValue.qa = {
												is_done: false,
												user: "",
												dt_qa: "",
											};
										}
										//const newData = { ...data, ...updateValue, modified: true };
										//setData(newData);
										let new_commessa = await saveCommessa(updateValue);
										const newData = { ...data, ...new_commessa, modified: true };
										setData(newData);
									}
								}}
								style={{
									marginRight: "16px",
								}}
							/>
							<Checkbox
								id="chk_finito"
								large
								checked={data.cd_status == COMMESSA_STATUS.FINITO}
								onChange={async (e) => {
									if(canEdit(user)){
										const checked = e.target.checked;
										const updateValue = {
											status_history: data.status_history
										};
										if (checked) {
											updateValue.cd_status = COMMESSA_STATUS.FINITO;
										} else {
											updateValue.cd_status = COMMESSA_STATUS.CONFERMATO;
										}
										updateValue.status_history[data.cd_status] = moment().toISOString();

										//const newData = { ...data, ...updateValue, modified: true };
										//setData(newData);
										//saveCommessa(updateValue);

										let new_commessa = await saveCommessa(updateValue);
										const newData = { ...data, ...new_commessa, modified: true };
										setData(newData);

									}
								}}
								style={{
									marginRight: "16px",
								}}
							/>
						</div>

						<div
							className="cell-padding"
							style={{
								display: "flex",
								width: "10%",
							}}
						>
							<div
								title="Jet 625"
								style={{
									marginRight: "16px",
								}}
							>
								<Checkbox
									id="chk_machine_Jet625"
									isCircular
									large
									disabled
									checked={data.machine_Jet625 || (data.punteggi_lavorazioni.tga.initial_value > 0 || data.punteggi_lavorazioni.tgm.initial_value > 0)}
									color={data.machine_Jet625 ? "var(--green)" : data.punteggi_lavorazioni.tga.initial_value > 0 || data.punteggi_lavorazioni.tgm.initial_value > 0 ? "var(--yellow)" : "var(--grey-2)"}
								/>
							</div>
							<div
								title="Primus 402"
								style={{
									marginRight: "16px",
								}}
							>
								<Checkbox
									id="chk_machine_Primus402"
									isCircular
									large
									disabled
									checked={data.machine_Primus402 || (data.punteggi_lavorazioni.wja.initial_value > 0 || data.punteggi_lavorazioni.wjm.initial_value > 0)}
									color={data.machine_Primus402 ? "var(--green)" : data.punteggi_lavorazioni.wja.initial_value > 0 || data.punteggi_lavorazioni.wjm.initial_value > 0 ? "var(--yellow)" : "var(--grey-2)"}
								/>
							</div>
							<div
								title="M38-5"
								style={{
									marginRight: "16px",
								}}
							>
								<Checkbox
									id="chk_machine_M38_5"
									isCircular
									large
									disabled
									checked={data.machine_M38_5 || data.punteggi_lavorazioni.cnc.initial_value > 0}
									color={data.machine_M38_5 ? "var(--green)" : data.punteggi_lavorazioni.cnc.initial_value > 0 ? "var(--yellow)" : "var(--grey-2)"}
								/>
							</div>
							<div
								title="Proliner 8X"
								style={{
									marginRight: "16px",
								}}
							>
								<Checkbox
									id="chk_machine_Proliner_8X"
									isCircular
									large
									disabled
									checked={data.machine_Proliner8X}
									color={data.machine_Proliner8X ? "var(--green)" : "var(--grey-2)"}
								/>
							</div>
						</div>
					</>
				)}
			</Div>
			{data.cd_type != COMMESSA_TYPES.ATTIVITA && getSumPunteggiLavorazione(false) > 0 ? (
				<div
					className="cell-padding"
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "end"
					}}
				>
					<ProgressBar
						now={(
							((getSumPunteggiLavorazione(false) -
								getSumPunteggiLavorazione(true)) *
								100) /
							getSumPunteggiLavorazione(false)
						).toFixed(2)}
						className={"small"}
						color="var(--red)"
						label={`${(
							getSumPunteggiLavorazione(false) - getSumPunteggiLavorazione(true)
						).toFixed(2)}/${getSumPunteggiLavorazione(false)}`}
						style={{
							maxWidth: "100%",
							alignSelf: "end",
							width: `${data.bar_width ? data.bar_width : 0}%`,
						}}
					/>
				</div>
			) : (
				""
			)}
			{
				showProcessingModal ?
					<ProcessingNotesModal
						show={showProcessingModal}
						commessa={data}
						onChange={(commessa) => {
							setData(commessa);
							if (props.onChange) {
								props.onChange(commessa);
							}
						}}
						onHide={() => setShowProcessingModal(false)}
						onCancel={() => setShowProcessingModal(false)}
					/>
				:
				""
			}
			{
				showAttivitaModal ?
					<NewActivityModal
						show={showAttivitaModal}
						commessa={data}
						onSuccess={(commessa) => {
							setData(commessa);
							if (props.onChange) {
								props.onChange(commessa);
							}
						}}
						onDelete={(commessa) => {
							if (props.onDelete) {
								props.onDelete(commessa);
							}
						}}
						onHide={() => setShowAttivitaModel(false)}
						onCancel={() => setShowAttivitaModel(false)}
					/>
				:
				""
			}
		</Wrapper>
	);
};
