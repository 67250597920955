export const USER_ROLES = {
	ADMIN: "CMS-ACCESS-SUPERADMIN",
	OPERATOR: "CMS-ACCESS-OPERATOR",
	CLIENT: "CMS-ACCESS-CLIENT",
};

export const PUNTEGGI_LAVORAZIONI_STATUS = {
	GREY: "GREY",
	RED: "RED",
	YELLOW: "YELLOW",
	YELLOWRED: "YELLOW_RED_YELLOWGREEN",
	YELLOWGREEN: "YELLOWGREEN",
	GREEN: "GREEN",
	BLUE: "BLUE"
};

export const PUNTEGGI_LAVORAZIONI_NEXT_STATUS = {
	mat: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		RED: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.RED,
	},
	tgm: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	tga: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		RED: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.RED,
	},
	coste: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	wjm: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	wja: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		RED: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.RED,
	},
	cnc: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		RED: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.RED,
	},
	alz: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN,
		YELLOWGREEN: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	inc: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	fin: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
	},
	imb: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		BLUE: PUNTEGGI_LAVORAZIONI_STATUS.BLUE
	},
	acc: {
		GREY: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
		RED: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
		YELLOW: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
		GREEN: PUNTEGGI_LAVORAZIONI_STATUS.RED,
	},
};

export const PRIORITA = [
	{
		text: "Tutte le priorità",
		value: "-",
	},
	{
		text: "NOI",
		value: "NOI",
	},
	{
		text: "LORO",
		value: "LORO",
	},
	{
		text: "TAP",
		value: "TAP",
	},
	{
		text: "CAP",
		value: "CAP",
	},
	{
		text: "CAT",
		value: "CAT",
	},
	{
		text: "IN CODA",
		value: "IN_CODA",
	},
];

export const ZONE = [
	{
		text: "Tutte le zone",
		value: "-",
	},
	{
		text: "15KM",
		value: "15KM",
	},
	{
		text: "200KM-E",
		value: "200KM_E",
	},
	{
		text: "ALBA",
		value: "ALBA",
	},
	{
		text: "AO",
		value: "AO",
	},
	{
		text: "AT-AL",
		value: "AT_AL",
	},
	{
		text: "BARG",
		value: "BARG",
	},
	{
		text: "BIEL",
		value: "BIEL",
	},
	{
		text: "BSD",
		value: "BSD",
	},
	{
		text: "CAN-E",
		value: "CAN_E",
	},
	{
		text: "CAN-W",
		value: "CAN_W",
	},
	{
		text: "CAR",
		value: "CAR",
	},
	{
		text: "CHIE",
		value: "CHIE",
	},
	{
		text: "CN",
		value: "CN",
	},
	{
		text: "COST",
		value: "COST",
	},
	{
		text: "EDI",
		value: "EDI",
	},
	{
		text: "FR",
		value: "FR",
	},
	{
		text: "MONC",
		value: "MONC",
	},
	{
		text: "MOND",
		value: "MOND",
	},
	{
		text: "NONE",
		value: "NONE",
	},
	{
		text: "NOV",
		value: "NOV",
	},
	{
		text: "ORB",
		value: "ORB",
	},
	{
		text: "PIN",
		value: "PIN",
	},
	{
		text: "POIR",
		value: "POIR",
	},
	{
		text: "SALU",
		value: "SALU",
	},
	{
		text: "SAVI",
		value: "SAVI",
	},
	{
		text: "SETT",
		value: "SETT",
	},
	{
		text: "SPED",
		value: "SPED",
	},
	{
		text: "TO-1",
		value: "TO_1",
	},
	{
		text: "TO-2",
		value: "TO_2",
	},
	{
		text: "TO-3",
		value: "TO_3",
	},
	{
		text: "TO-4",
		value: "TO_4",
	},
	{
		text: "TO-5",
		value: "TO_5",
	},
	{
		text: "VAL S",
		value: "VAL_S",
	},
	{
		text: "VENA",
		value: "VENA",
	},
	{
		text: "VERC",
		value: "VERC",
	},
];

export const GRUPPI_ANAGRAFICHE = [
	{
		text: "-",
		value: "-",
	},
	{
		value: "ARCHITETTI",
		text: "ARCHITETTI",
	},
	{
		value: "ARREDATORI_NEGOZI",
		text: "ARREDATORI NEGOZI",
	},
	{
		value: "CAMPRA_MARMI_SRL",
		text: "CAMPRA MARMI SRL",
	},
	{
		value: "CENTRO_INOX",
		text: "CENTROINOX",
	},
	{
		value: "FALEGNAMERIE",
		text: "FALEGNAMERIE",
	},
	{
		value: "IMPRESA_EDILE",
		text: "IMPRESA EDILE",
	},
	{
		value: "MAGAZZINO_EDILE_CERAMISTI",
		text: "MAGAZZINO EDILE CERAMISTI",
	},
	{
		value: "MARMISTI",
		text: "MARMISTI",
	},
	{
		value: "MOBILIERI",
		text: "MOBILIERI",
	},
	{
		value: "PRIVATO",
		text: "PRIVATO",
	},
];

export const AGENTI = [
	{
		text: "-",
		value: "-",
	},
	{
		value: "ARREDANET",
		text: "ARREDANET",
	},
	{
		value: "BELLOCCHIA",
		text: "BELLOCCHIA",
	},
	{
		value: "EMANUELE",
		text: "EMANUELE",
	},
	{
		value: "EZIO",
		text: "EZIO",
	},
	{
		value: "MARCO",
		text: "MARCO",
	},
	{
		value: "CARLO",
		text: "CARLO",
	},
];

export const STATUS = {
	"0" : "PRENOTAZIONE", //'PRENOTAZIONE',
	"50" : "PREORDINE", //'PREORDINE',
	"100" : "IN ATTESA", //'IN_ATTESA',
	"200" : "CONFERMATO", //'CONFERMATO',
	"500" : "FINITO", //'FINITO'
};

export const COMMESSA_STATUS = {
	PRENOTAZIONE: "0", //'PRENOTAZIONE',
	PREORDINE: "50", //'PREORDINE',
	IN_ATTESA: "100", //'IN_ATTESA',
	CONFERMATO: "200", //'CONFERMATO',
	FINITO: "500", //'FINITO'
};

export const COMMESSA_STATUS_COLOR = {
	"0": "var(--purple)", //'PRENOTAZIONE',
	"50": "var(--blue)", //'PREORDINE',
	"100": "var(--red)", //'IN_ATTESA',
	"200": "var(--black)", //'CONFERMATO',
	"500": "var(--grey-2)", //'FINITO'
};

export const COMMESSA_TYPES = {
	COMMESSA: "COMMESSA",
	PREVENTIVO: "PREVENTIVO",
	ORDINE: "ORDINE",
	SPEDIZIONE: "SPEDIZIONE",
	ATTIVITA: "ATTIVITA",
};

export const OBIETTIVO_LAVORAZIONE_GIORNALIERA = 100;

export const LAVORAZIONI = [
	{
		value: "tgm",
		text: "taglio manuale",
		noteDescriptor: "TAGLIO MANUALE",
	},
	{
		value: "tga",
		text: "taglio automatico",
		noteDescriptor: "TAGLIO AUTOMATICO",
	},
	{
		value: "coste",
		text: "coste",
		noteDescriptor: "COSTE",
	},
	{
		value: "wjm",
		text: "waterjet manuale",
		noteDescriptor: "WTJ MANUALE",
	},
	{
		value: "wja",
		text: "waterjet automatico",
		noteDescriptor: "WTJ AUTOMATICO",
	},
	{
		value: "cnc",
		text: "cnc",
		noteDescriptor: "CNC",
	},
	{
		value: "alz",
		text: "alzatine",
		noteDescriptor: "ALZATINE",
	},
	{
		value: "acc",
		text: "accessori",
		noteDescriptor: "ACCESSORI",
	},
	{
		value: "inc",
		text: "incollaggio",
		noteDescriptor: "INCOLLAGGIO",
	},
	{
		value: "fin",
		text: "finitura",
		noteDescriptor: "FINITURA",
	},
];

export const USER_ROLES_PREVENTIVI = [
	{
		value: "-",
		text: "Tutte le tipologie",
	},
	{
		value: "CMS-ACCESS-SUPERADMIN",
		text: "Admin",
	},
	{
		value: "CMS-ACCESS-CLIENT",
		text: "Cliente",
	}
];


export const DEFAULT_IMB = {prezzo: 31};
